import axios, { AxiosRequestConfig } from 'axios'
import { Base64 } from 'js-base64'
import { gzip as _gzip, InputType } from 'zlib'

/** 文件指令格式化 */
interface Formatter {
  /** 自定义命令行转换函数 */
  format?: (filename: string, data: string) => string;
}

/** 指令拼接符 */
interface JoinOperator {
   /**
   * 语句拼接字符
   * @default '&&'
   */
  joinOperator?: string;
}

interface EnsureDir {
  /**
   * 是否创建不存在的目录
   */
  ensureDir?: boolean;
}

/** 基础文件导出选项 */
interface BaseCommandFileOptions {
  /** 生成文件的路径+文件名 */
  filename: string;
  /** 添加执行权限 */
  addExec?: boolean;
  /** 自定义执行权限添加内容 */
  formatExec?: (filename: string) => string;
  beforeScript?: string;
}

/** 网络请求基础文件导出选项 */
interface RequestBaseCommandFileOptions extends BaseCommandFileOptions, Formatter, JoinOperator {
  request: AxiosRequestConfig;
}

/** 本地 */
interface LocalCommandFileOptions extends BaseCommandFileOptions, Formatter, JoinOperator {
  /** 文件数据 */
  data: string;
}

/** 文件导出选项 */
export interface CommandFileOptions extends LocalCommandFileOptions, Formatter, JoinOperator, EnsureDir {}

/** 基础多个文件导出选项 */
interface BaseMultipleCommandFileOptions<FO extends BaseCommandFileOptions> extends Formatter, JoinOperator, EnsureDir {
  /** 要导出的文件 */
  files: FO[];
}

/** 多个文件导出选项 */
export type MultipleCommandFileOptions = BaseMultipleCommandFileOptions<LocalCommandFileOptions>
/** 网络请求多个文件导出选项 */
export type MultipleCommandFileRequestOptions = BaseMultipleCommandFileOptions<RequestBaseCommandFileOptions | LocalCommandFileOptions>

function gzip (buf: InputType): Promise<Buffer> {
  return new Promise((resolve, reject) => {
    _gzip(buf, (err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  })
}

function isRequestBaseCommandFileOptions (option: BaseCommandFileOptions): option is RequestBaseCommandFileOptions {
  return (option as RequestBaseCommandFileOptions).request !== undefined
}

function formatJoinOperator (joinOperator: string | undefined): string | undefined {
  return joinOperator ? ` ${joinOperator.trim()} ` : undefined
}

function generateMkdirCommands (filePaths: string[], joinOperator = '&&'): string {
  const directoryPaths = filePaths.map((filePath) => {
    const parts = filePath.split('/')
    parts.pop()
    return parts.join('/')
  })

  const uniqueDirectoryPaths = Array.from(new Set(directoryPaths))
  const sortedDirectoryPaths = uniqueDirectoryPaths.sort((a, b) => b.length - a.length)

  const paths: string[] = []
  for (const directoryPath of sortedDirectoryPaths) {
    let found = false
    for (const existingCommand of paths) {
      if (existingCommand.startsWith(directoryPath)) {
        found = true
        break
      }
    }
    if (!found) {
      paths.push(directoryPath)
    }
  }

  return paths.map((e) => `mkdir -m a+rw -p ${e}`).join(formatJoinOperator(joinOperator))
}

/** 生成文件导出命令行 */
function createCommandFile (option: CommandFileOptions) {
  const { filename, data, joinOperator = '&&', ensureDir, beforeScript } = option
  const format = option.format || ((_filename, _data) => `echo "${_data}" | base64 -d | gunzip > ${_filename}`)

  let command = format(filename, data)

  if (option.addExec) {
    const formatExec = option.formatExec || (_filename => `chmod +x ${_filename}`)
    command = `${command}${formatJoinOperator(joinOperator)}${formatExec(filename)}`
  }

  if (ensureDir) {
    const mkdirCommand = generateMkdirCommands([filename], joinOperator)
    if (mkdirCommand) {
      command = `${mkdirCommand}${formatJoinOperator(joinOperator)}${command}`
    }
  }

  if (beforeScript) {
    command = `${beforeScript}${formatJoinOperator(joinOperator)}${command}`
  }

  return command
}

export async function requestCommandFiles (option: MultipleCommandFileRequestOptions) {
  const { files, joinOperator = '&&', format, ensureDir } = option

  const commands: string[] = []

  for (const f of files) {
    if (isRequestBaseCommandFileOptions(f)) {
      const { data } = await axios.request(f.request)
      let textData = ''
      if (data instanceof Uint8Array) {
        textData = Base64.fromUint8Array(await gzip(data), false)
      } else if (data instanceof Blob) {
        textData = Base64.fromUint8Array(await gzip(new Uint8Array(await data.arrayBuffer())), false)
      } else {
        try {
          textData = Base64.fromUint8Array(await gzip(data), false)
        } catch {
          textData = Base64.fromUint8Array(await gzip(data + ''), false)
        }
      }

      commands.push(createCommandFile({
        ...f,
        data: textData,
        format,
        joinOperator
      }))
    } else {
      commands.push(createCommandFile({
        ...f,
        format,
        joinOperator
      }))
    }
  }

  if (ensureDir) {
    const paths = files.map(f => f.filename)
    const mkdirCommand = generateMkdirCommands(paths, joinOperator)

    if (mkdirCommand) {
      commands.unshift(mkdirCommand)
    }
  }

  return commands.join(formatJoinOperator(joinOperator))
}
