
import { Component, Ref, Watch } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import BaseTablePanel from '@/frame/share/table-panel'
import tableConfig from './index-table.config'
import PcServerApi, { PcServer } from '@/api/maintain/pc-server'
import { PagerQueryBean, PageListData, PageDataApiResult } from '@/libs/http-request'
import { PageInfo } from '@/libs/api-request'
import ElevatorRabbitmq from '@/mixins/elevator-rabbitmq'
import { ApplicationWebSocket, ServerStatus } from '@/libs/application-websocket'
import { getterUserInfo } from '@/store'
import initPcServerModal from './init-pc-server-modal.vue'
import { showModal } from '@/libs/modal-helper'
import appInstanceManger from '@/api/maintain/app-instance-manger'
import { downfile } from '@/libs/request'
import _ from 'lodash'
import moment from 'moment'
import certMangerApi from '@/api/maintain/cert-manger'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: []
}
@Component({
  name: 'dataList',
  components: { ExpendSearchPanel, TablePanel },
  mixins: [ElevatorRabbitmq]
})
export default class DataList extends BaseTablePanel<PcServer> {
  // 当前状态
  loading = false;
  /// 表格定义
  tableConfig = tableConfig;

  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery);

  @Ref()
  readonly tablePanel!: TablePanel | null;

  /**
   * 页面数据
   */
  pageData: PageListData<PcServer> = {
    items: [],
    total: 0,
    pageSize: 10
  };

  firstPcServerId = 0;

  /**
   * websock实例列表
   * @key 服务器id
   * @value websock实例
   */
  websocketInstance: Record<number, ApplicationWebSocket> = {};

  rowClassName ({ row }: any) {
    return !this.opening && row.id !== this.firstPcServerId ? 'expired' : 'normal'
  }

  /**
   * 关闭所有ws链接
   */
  closeAllWebsocket () {
    Object.values(this.websocketInstance).forEach((ws) => {
      ws.close()
    })
  }

  /**
   * 当前编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canOperatePcServer
  }

  /**
   * 是否开通企业用户
   */
  get opening () {
    const user = getterUserInfo()
    return user.operaExpirationTime && moment(user.operaExpirationTime).isAfter(moment.now())
  }

  /**
   * 当前是否可新增服务器
   */
  get canAdd () {
    return this.opening || this.pageData.total < 1
  }

  activated () {
    if (!this.opening) {
      PcServerApi.getFirstPcServerId().then((res) => {
        this.firstPcServerId = res.data || 0
      })
    }
    this.doPullDataList()
  }

  /**
   * 加载数据
   */
  mounted () {
    this.$nextTick(() => {
      this.doPullDataList()
    })

    /// 校验服务器状态
    this.checkServerStatus()
  }

  /**
   * 收缩搜索条
   */
  shrinkSeachbar () {
    const newQuery = _.cloneDeep(defaultQuery)
    if (!_.isEqual(newQuery, this.queryData)) {
      this.$nextTick(() => {
        this.queryData = newQuery
        this.doPullDataList()
      })
    }
  }

  /**
   * 页面数据拉取
   */
  public doPullDataList (pageInfo?: PageInfo) {
    pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

    const that = this

    this.pullDataList(pageInfo).then((response) => {
      response.items.forEach((item) => {
        item.serverStatusEn = 'unknown'
        item.serverStatusText = '未知'
        item.serverStatusTimestamp = Date.now()
      })
      that.pageData = response;
      (that.$refs.tablePanel as any).setCurPage(pageInfo?.curPage)

      // 建立ws连接
      that.closeAllWebsocket()
      that.subscribeServersStatus(that.pageData.items)
    })
  }

  /**
   * 工具栏按钮事件
   */
  public doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(code, (this.$refs.tablePanel as any).getTableObject())
  }

  /**
   * 从服务器拉取数据
   */
  protected onPullDataListFromServer (
    pagerQueryBean: PagerQueryBean
  ): Promise<PageDataApiResult<PcServer>> {
    return PcServerApi.query(pagerQueryBean)
  }

  /**
   * 时间范围改变事件
   */
  onDateRangeChange (value: Array<string>) {
    if (value) {
      this.queryData.dateRage = [value[0], value[1]]
    }
  }

  /**
   * 弹出对话框确认进行pc初始化
   * @param pcServer
   */
  showInitPcServerModal (pcServer: PcServer) {
    showModal(initPcServerModal, { props: { pcServerId: pcServer.id } }, false, () => {
      this.closeAllWebsocket()
      this.subscribeServersStatus(this.pageData.items)
      return new Promise<void>((resolve, reject) => resolve())
    })
  }

  /**
   * 删除服务器
   */
  deleteItemById (id: number) {
    this.$Modal.confirm({
      title: '确认',
      content: '<p>确认要删除此服务器吗？</p>',
      onOk: () => {
        PcServerApi.deleteItemById(id)
          .then((res) => {
            if (id === this.firstPcServerId) {
              PcServerApi.getFirstPcServerId().then((res) => {
                this.firstPcServerId = res.data || 0
              })
            }
            this.doPullDataList()
            this.$Message.success('删除成功')
          })
          .catch((error) => {
            this.$Message.error(error.message)
          })
      }
    })
  }

  /// 复制脚本失败
  copyShellUrlFail () {
    this.$Message.error('复制失败')
  }

  /// 根据返回的服务器列表，订阅mq
  /// 其他用法详见 src/mixins/elevator-rabbitmq.ts
  /// 连接函数，不需要手动调用断开，切换监听条件重新connent即可
  subscribeServersStatus (items: Array<PcServer>) {
    const that = this
    items.forEach((item) => {
      appInstanceManger
        .getServerStatusMonitorWebSocket(
          item.id!,
          location.protocol === 'https:' ? item.httpsManagerPort : item.mangerPort,
          {
            callback: (data) => {
              that.pageData.items.forEach((x) => {
                if (x.id === Number(data.serverId)) {
                  // 更新当前记录的服务器状态
                  that.$nextTick(() => {
                    x.serverStatusEn =
                      data.status === ServerStatus.running ? 'online' : 'offline'
                    x.serverStatusText =
                      data.status === ServerStatus.running ? '在线' : '离线'
                    x.serverStatusTimestamp = Date.now()
                    x.certStartTime = data.sslStartTime
                    x.certEndTime = data.sslEndTime
                    if (x.certEndTime !== undefined) {
                      const endTime = moment(
                        x.certEndTime,
                        'YYYY-MM-DD HH:mm:ss'
                      ).toDate()
                      x.certTimeExpired = new Date() >= endTime
                    } else {
                      x.certTimeExpired = true
                    }
                  })
                }
              })
            },
            fixed: 2000
          }
        )
        .then((ws) => {
          // 添加到实例
          this.websocketInstance[item.id!] = ws
        })
    })
  }

  /**
   * 查询定时器标签
   */
  timer: any = null;

  /// 检查校验服务器状态
  checkServerStatus () {
    const that = this
    // 没3秒查一下
    this.timer = setInterval(() => {
      // 没有列表返回，则不查询
      if (that.pageData.items.length <= 0) {
        return
      }
      that.pageData.items.forEach((item) => {
        if (item.serverStatusText === '在线') {
          const serverStatusTimestamp = item.serverStatusTimestamp!
          const now = Date.now()
          // 如果状态是在线，但是最近的更新时间距离现在已经超过6秒，视为离线
          if (now - serverStatusTimestamp >= 6000) {
            item.serverStatusEn = 'offline'
            item.serverStatusText = '离线'
          }
        }
      })
    }, 3000)
  }

  /**
   * 下载指引
   */
  downloadGuide () {
    downfile(
      {
        method: 'GET',
        url: '/xbgcoding%20root%20certificate%20import%20guide.pdf'
      },
      'application/pdf'
    ).then((url) => {
      window.open(url)
    })
  }

  /**
   * 下载根证书
   */
  downRootCertFile () {
    certMangerApi
      .downRootCertFile()
      .then((url) => {
        const saveLink = document.createElement('a')
        saveLink.href = url
        saveLink.download = 'xbgroot.crt'
        saveLink.click()
      })
      .catch((ex) => {
        this.$Message.error('下载证书失败:' + ex.message)
      })
  }

  /** 重新生成服务证书 */
  reCreateServerCertData (pcServerId: number) {
    const that = this as any
    certMangerApi
      .reCreateServerCertData(pcServerId)
      .then((resp) => {
        const cmdStr = `mkdir -p ${process.env.VUE_APP_PODMAN_ROOTDIR} && echo "${resp.data?.pwd}" >${process.env.VUE_APP_PODMAN_ROOTDIR}/podmanServer.key && echo "${resp.data?.data}"| base64 -d >${process.env.VUE_APP_PODMAN_ROOTDIR}/podmanServer.pfx`
        that.$clipboard(cmdStr)
        that.$Message.info(
          '生成了证书并已经将指令复制到剪贴板，您只需到服务器指令指令即可'
        )
      })
      .catch((ex) => {
        this.$Message.error('重新生成服务证书失败:' + ex.message)
      })
  }

  beforeDestroy () {
    this.timer && clearInterval(this.timer)
    this.closeAllWebsocket()
  }
}
