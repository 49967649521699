
import { BaseModal } from '@/libs/modal-helper'
import { Component, Prop, Vue } from 'vue-property-decorator'
import pcServerApi, { PcServer } from '@/api/maintain/pc-server'
import certMangerApi from '@/api/maintain/cert-manger'
import { requestCommandFiles } from '@/libs/command-file-helper'
import _ from 'lodash'

@Component({
  name: 'initpcModal'
})
export default class extends Vue implements BaseModal {
  // 显示状态
  visiable = false

  @Prop({
    type: Number,
    required: true
  })
  pcServerId!: number

  pcServer: PcServer = {} as PcServer

  /// 显示对话框
  show (): void {
    this.visiable = true
  }

  /// 关闭对话框
  close (): void {
    this.visiable = false
  }

  /// 保存处理
  doSave (): void {
    this.$emit('onOk')
  }

  /// 取消处理
  doCancel (): void {
    this.$emit('onCancel')
  }

  created () {
    this.loadPcServerData()
  }

  /**
   * 重制脚本
   */
  copyShCmd () {
    /// 检查管理端口是否设置
    if (
      this.pcServer.mangerPort === undefined ||
      this.pcServer.mangerPort <= 0
    ) {
      this.$Message.error('服务器的管理端口没有设置')
      return
    }

    const that = this as any
    let initCertCmd = ''
    let fileCmd = ''

    const shellFileName = _.lowerCase(this.pcServer.serverSystem).replace(
      /\s/g,
      ''
    )

    requestCommandFiles({
      ensureDir: true,
      files: [
        {
          request: {
            method: 'GET',
            url: `/deploy/xbgserver.init.${shellFileName}.sh?t=${new Date().getTime()}`
          },
          filename: './init.sh',
          addExec: true
        },
        {
          request: {
            method: 'GET',
            url: '/api/maintain/certmanger/downRootCertFile'
          },
          filename: '/etc/pki/ca-trust/source/anchors/xbgroot.crt'
        },
        {
          request: {
            method: 'GET',
            url: `/deploy/registries.conf?t=${new Date().getTime()}`
          },
          filename: '/etc/containers/registries.conf',
          beforeScript: 'if [ -f /etc/containers/registries.conf ]; then mv -f /etc/containers/registries.conf /etc/containers/registries.conf.bak; fi'
        }
      ]
    })
      .then(_fileCmd => {
        fileCmd = _fileCmd
        return certMangerApi
          .reCreateServerCertData(this.pcServerId)
      })
      .then(resp => {
        initCertCmd = `mkdir -p ${process.env.VUE_APP_PODMAN_ROOTDIR} && echo "${resp.data?.pwd}" >${process.env.VUE_APP_PODMAN_ROOTDIR}/podmanServer.key   && echo "${resp.data?.data}"| base64 -d >${process.env.VUE_APP_PODMAN_ROOTDIR}/podmanServer.pfx`
        return pcServerApi.initPodmanServiceAtPcServer(this.pcServerId)
      })
      .then(res => {
        const cmdStr = `./init.sh ${
          this.pcServer.mangerPort
        } ${res.data!} ${window.location.protocol.substring(
          0,
          window.location.protocol.length - 1
        )} ${window.location.host}`
        that.$clipboard(fileCmd + ' && ' + initCertCmd + ' && ' + cmdStr)
        this.$Message.success('服务器已经重新生成了密钥')
        this.visiable = false
        this.$emit('onOk')
      })
      .catch(ex => this.$Message.error('操作失败:' + ex.message))
  }

  /**
   * 加载服务器数据
   */
  private loadPcServerData () {
    pcServerApi
      .getItemById(this.pcServerId)
      .then(res => {
        this.pcServer = res.data!
      })
      .catch(ex => this.$Message.error('加载服务器数据失败:' + ex.message))
  }
}
